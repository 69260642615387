import React, { useState } from "react";
import First from '../../assets/images/home/thirdSection/3.jpg';
import { ArrowRight } from "react-feather";
import MyInput from "../Input/MyInput";
import Button from "../Button/Button";
import Service from "../../Service/Service";



const ContactForm=props=>{

    const [name,setName]=useState();
    const [email,setEmail]=useState();
    const [phone,setPhone]=useState();
    const [subject,setSubject]=useState();
    const [message,setMessage]=useState();
    const [toast,showToast]=useState(false)
    const [error,setError]=useState('')

    const submit=()=>{
        if(!name){
            setError('Please Enter Name');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!email){
            setError('Please Enter Email');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!phone){
            setError('Please Enter Phone no');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!subject){
            setError('Please Enter Subject');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
          if(!message){
            setError('Please Enter Message');
            showToast(true);
            setTimeout(()=>{showToast(false);},4000)
            return;
          }
        let params={
            name,
            email,
            phone,
            subject,
            message
        }
        Service.addContact(params).then(response=>{
          document.getElementById('my_modal_5').showModal()
        }).catch(err=>{
            console.log(err)
        })
    }


    return(
        <div className="mb-[50px]">
                  {
          toast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info bg-[red]">
      <span className="text-white text-lg">{error}</span>
    </div>
          </div>
  
    
  </div>
        }

            <div className='flex flex-wrap'>

                <div className='w-full flex items-center justify-center px-2 py-2 lg-1:p-10 bg-secondary'>
                    <div>
                    <p className='text-white text-4xl tracking-[1px]'>
                    CONTACT
                        </p>
                        <p className='text-white text-sm mb-3 tracking-[4px]'>
                         Fill The Form Below ( Choose Any )
                        </p>
                        
                        <div className="flex flex-col gap-3 py-10">
                            <MyInput
                            placeholder='Name'
                            onChange={(e)=>setName(e.target.value)}
                            />
                            <MyInput
                            placeholder='Email'
                            onChange={(e)=>setEmail(e.target.value)}
                            />
                            <MyInput
                            placeholder='Phone'
                            onChange={(e)=>setPhone(e.target.value)}
                            />
                            <MyInput
                            placeholder='Subject'
                            onChange={(e)=>setSubject(e.target.value)}
                            />
                            <MyInput
                            placeholder='Message'
                            type='textarea'
                            inputClass='h-[200px]'
                            onChange={(e)=>setMessage(e.target.value)}
                            />
                        </div>
                        
                        <button className="btn rounded-xl bg-primary my-2" onClick={submit}>Submit</button>
                    </div>
                </div>
                {/* <div className='w-full flex justify-end lg-1:w-1/2'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2358.8606613216994!2d-2.4675666241040797!3d53.756364143966906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b9e4cdd1c589d%3A0x973b19d254ca442d!2s30%20Harwood%20Gate%2C%20Blackburn%20BB1%205HP%2C%20UK!5e0!3m2!1sen!2s!4v1697053073044!5m2!1sen!2s" width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div> */}

            </div>

            <dialog id="my_modal_5" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white" onClick={()=>{window.location.reload()}}>✕</button>
    </form>
    <p className="text-xl text-white p-8">Contact Submitted</p>
  </div>
</dialog>            
        </div>
    )
}
export default ContactForm
