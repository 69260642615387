import React from "react";
import topcover from "../../assets/images/contact/1.jpg";



const UmrahVisa=props=>{

    const data=[
        {
            heading:`Get Urgent Umrah Visa only to Perform Spirtual Duty in Makkah & Madinah`,
            para:`Hajj and Umrah are two of the most beautiful forms of worship bestowed to Muslims to not only get reward of them being offered but also get the reward of being closer to our Creator. The opportunity to pay visit to places where the beloved Prophet (PBUH) spent the holy time of his life is already another great hall mark of Hajj and Umrah. In order to visit another country and in our case Saudi Arabia, the most important requirement is to get Saudi Umrah visa. Most people get triggered by the process of getting Umrah visa only. However, below mentioned are the simple and comprehensive steps to guide about Umrah Visa UK to our worthy customers in United Kingdom.`
        },
        {
            heading:`Urgent UMRAH Deals as low as £70`,
            para:`Requirements for Saudi Umrah visa:`
        },
        {
            heading:``,
            para:`The first and foremost step to obtain Umrah visa UK, the residents of UK needs to get an Umrah visa application form and to get it duly filled up. To obtain this form, the candidate needs to get the form from the nearest Saudi Consulate. Candidates applying for Umrah visa UK with a non-Muslim name needs to get a form from the nearest mosque declaring them as Muslims.`
        },
        {
            heading:``,
            para:`Another important requirement for Umrah visa is four photographs of passport size and white background. Photographs need to be full fazed and should not be taken from a certain inclined angle. `
        },
        {
            heading:``,
            para:`For Saudi Umrah visa, the passport should be valid for at least 6 months from the date of submission of the application form.`
        },
        {
            heading:``,
            para:`Travel to Saudi Arabia requires a woman to travel with a Mahram. So Saudi Umrah visa requires relationship certificate (marriage certificate in case of wife or birth certificate for a child with name of both parents).`
        },
        {
            heading:``,
            para:`Certificate of vaccination against meningitis is also another very important document for Saudi Umrah visa.`
        },
        {
            heading:`Validity of Saudi Umrah Visa:`,
            para:`Umrah visa only remains valid for two weeks  and all the necessary prayers should be performed within these two weeks making sure you departure within these two weeks. In case of Ramadan Umrah visa, Umrah should be performed within the month of Ramadan and should not exceed the last day of Ramadan.`
        },
        {
            heading:`Umrah visa only Deals`,
            para:`Residents of UK looking for Umrah visa only finds numerous travel experts agents offering various packages the customers do not want to avail. A call of good news for people residing in UK to get Umrah visa only through Hajj Umrah Hub is our main priority. Don’t stress out or think twice and call out for guidance from professional travel experts.`
        },
        {
            heading:`A Chance to pay visit to Holy sites- get Umrah visa UK now`,
            para:`Hajj Umrah Hub being an ideal travel guide for several years wants their valued customers to experience the spirit of being close to their Creator. Apart from various lavish packages that Hajj Umrah Hub brings we also provide proper guide to those looking for Umrah visa UK. Remember this might be the time you can please your Lord by investing for Akhirah from what your Lord has granted you with. `
        },
        {
            heading:``,
            para:`Let it be Masjid al Haram (House of Allah) or Masjid e Nabvi (Mosque of Prophet PBUH), don’t know which might be your fortunate place for your wishes and prayers to be granted. So make use of your time and resources to get Umrah visa UK to start your holy journey of spiritual development.`
        },
        {
            heading:`UMRAH Visa Only  Dial 020-3838-03802`,
            para:``
        },
        {
            heading:`Get Urgent Umrah Visa only to Perform Spirtual Duty in Makkah & Madinah`,
            para:``
        },
    ]

    return(

        <div className='relative mt-3  p-10'>
            {
                data.map(x=>{
                    return <div className="my-6">
                         {x.heading? <p className=' text-2xl font-[600] tracking-[1px] leading-10'>
                    {x.heading}
                        </p>:''}
                        <p className=' text-md mb-2 tracking-[2px] leading-7'>
                         {x.para}
                        </p>
                    </div>
                })
            }
	
</div>
    )
}
export default UmrahVisa