import React, { useEffect, useState } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';



import HeadingSep from "../../assets/images/banner/heading-sep.png";
import CardsSection from "./CardsSection";
import Image from "../Contact/Image";
import { Link, useSearchParams } from "react-router-dom";
import Service from "../../Service/Service";


const CategorySection=({data,packages})=>{
  const [rating,setRating]=useState(3);
  const [searchParams, setSearchParams] = useSearchParams();
  
  console.log(window.location);

  useEffect(()=>{
    
    const token = searchParams.get('package')
console.log(token)//123

  },[window.location])


  return(
    

    <div className='flex flex-col justify-center shadow-md  rounded-lg  '>
                      <Image src={`${Service.baseURL}/uploads/${data.makkah_img1}`} classes='rounded-lg'/>
                   
  
  <button className="m-1 group px-10 py-5 bg-secondary text-white font-bold rounded-lg flex flex-col items-center justify-center gap-2 relative after:absolute after:h-full after:bg-primary z-20 shadow-lg after:-z-20 after:w-full after:inset-0 after:rounded-lg transition-all duration-300 hover:transition-all hover:duration-300 after:transition-all after:duration-500 after:hover:transition-all after:hover:duration-500 overflow-hidden cursor-pointer after:-translate-x-full after:hover:translate-x-0 [&amp;_p]:delay-200 [&amp;_p]:transition-all" onClick={()=>{}}><Link to={`/flight-detail?package=${data.uid}`}>{data.name}</Link></button>
 
  

  
                  </div>

  )
}






export default CategorySection