 
import LogoWhite from '../../assets/images/logoWhite.png'
import Logo from '../../assets/images/logo.png'
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { appConfig } from '../../appConfig'
import Bars from '../../assets/images/bars.svg'
import Service from '../../Service/Service'
import ContactForm from '../Contact/ContactForm'



const Navbar=props=>{

    const headRoute = appConfig.headRoute

    const [viewMenu,setViewMenu]  = useState(false)
    const [categories,setCategories]=useState([])
    const [tabindex,setTabIndex]=useState(1)

    const getCategories=()=>{
        
        Service.getAllCategory().then(response=>{
        
          setCategories(response.data)
        })
        }

        useEffect(()=>{
            getCategories();
        },[])

    const url = window.location.pathname
    console.log(' %% URL : ',url)

    return(
        <div className={`bg-${props.bg?'secondary':'transparent'} absolute top-0 z-[100] w-full `}>
             <DialogueContactUs/>
                     {/* <div className='navTopSection h-[40px] items-center px-14 border-b border-b-white hidden lg-1:flex'>
                         <div className='flex gap-8 text-xs font-normal text-white'>
                             <p>
                                 info@travel.co.uk
                             </p>
                             <p>
                                 073-082-327-84
                             </p>
                             <p>
                             30 Harwood Gate, Blackburn, UK
                             </p>
                         </div>
                     </div> */}
                
        <div className="navbar  ">
       
            <div className="navbar-start px-0 lg-1:px-14 w-full lg-1:w-1/4">
                <Link 
                className="btn btn-ghost normal-case text-xl"
                >
                    {/* {
                        url=='/'? */}
                        {/* <div className='logo'>
                            
                            <img src={LogoDark} className='w-[200px]'/>
                            
                        </div> */}
                         <div className='logo'>
                             <img src={Logo} className='w-[200px] hidden lg-1:block'/>
                             <img src={Logo} className='w-[200px] block lg-1:hidden'/>
                         </div>
                    
                </Link>
            </div>
            <div className="navbar-center hidden gap-4 lg-1:flex">
                
                {/* <div className="dropdown dropdown-hover ">
                    <label tabIndex={0} className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Pages</label>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black text-white w-52">
                        <li >
                            <Link className='bg-transparent font-poppins tracking-[2px]' to={'/about'}>
                                ABOUT US 
                            </Link>
                        </li>
                        <li >
                            <Link 
                        className='bg-transparent font-poppins tracking-[2px]'
                        to={`${headRoute}/contact`}
                        >
                            CONTACT US
                        </Link>
                    </li>
                    </ul>
                </div> */}
                <Link className='bg-transparent font-poppins tracking-[2px]' to={'/'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Fights</label>
                            </Link>
                <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=0&type=holiday`}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Holidays</label>
                            </Link>
                <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=0&type=umrah`}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Umrah Packages</label>
                            </Link>

                            {/* <div className="dropdown dropdown-hover">
  <label tabIndex={0} className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Holidays</label>
  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-primary rounded-box w-52">
  {
                                    categories.map(x=>{
                                        return <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=${x.uid}&type=holiday`}>
                                        <li className='p-2'>{x.name}</li>
                                    </Link>
                                        

                                    })
                                }
    
    
  </ul>
</div> */}
                


                            {/* <div className="dropdown dropdown-hover">
  <label tabIndex={0} className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Umrah Packages</label>
  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-primary rounded-box w-52">
  {
                                    categories.map(x=>{
                                        return <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=${x.uid}&type=umrah`}>
                                        <li className='p-2'>{x.name}</li>
                                    </Link>
                                        

                                    })
                                }
    
    
  </ul>
</div> */}
 
                           
        {/* <details>
          <summary tabIndex={0}>Parent</summary>
          <ul className="p-2">
          
          </ul>
        </details>
       */}
                            
                    
                    
                <Link className='bg-transparent font-poppins tracking-[2px]' to={'/umrahvisa'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Umrah Visa</label>
                            </Link>


                <Link className='bg-transparent font-poppins tracking-[2px]' role='button' onClick={()=>{ document.getElementById('my_modal_contact').showModal()}}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Contact Us</label>
                            </Link>
                {/* <Link className='bg-transparent font-poppins tracking-[2px]' to={'/aboutus'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">About Us</label>
                            </Link> */}
                
            </div>





            <div className="navbar-end flex lg-1:hidden">
                <div className="dropdown dropdown-end">
                    <label tabIndex={tabindex} className="btn btn-ghost rounded-btn">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="#ffffff" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>    
                         */}
                         <img src={Bars} height={'30px'} width={'30px'} role='button' onClick={()=>{
                          const button = document.getElementById('translateButton');
                          const element = document.getElementById('translateElement');
                        
                         // button.addEventListener('click', () => {
                            // Toggle translate-x-0 when the button is clicked
                            element.classList.toggle('-translate-x-full');
                            element.classList.toggle('translate-x-0');
                         // });
                            }}/>
                    </label>
                    <ul  id="translateElement" className="transition-transform transform -translate-x-full menu dropdown-content z-[100] bg-[#000033] p-2 shadow text-black  w-[100vw] min-h-screen  mt-0">
                        <li>
                            <ul className="menu w-full">
                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'/'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Flights</label>
                            </Link>
                                </li>

                                <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'/contact'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Contact Us</label>
                            </Link>
                                </li>
                                
                                <li>
                        <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=0&type=holiday`}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Holidays</label>
                            </Link>
                
                            {/* <ul className="menu w-full">

                                <li>
                                
                <label  className="m-1 text-white font-[600] text-lg tracking-[1px] leading-7">Umrah Packages</label>
                            
                            <ul>
                            {
                                    categories.map(x=>{
                                        return <li className='p-2 text-white'><a href={`/allpackages?category=${x.uid}&type=holiday`}>{x.name}</a></li>
                                    })
                                }
                            </ul>
                                </li>
                            </ul> */}
                        </li>
                        <li>
                                <Link className='bg-transparent font-poppins tracking-[2px]' to={'/umrahvisa'}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Umrah Visa</label>
                            </Link>
                                </li>
                        <li>
                        <Link className='bg-transparent font-poppins tracking-[2px]' to={`/allpackages?category=0&type=umrah`}>
                <label  className="cursor-pointer m-1 text-white tracking-[1px] leading-7">Umrah Packages</label>
                            </Link>
                            {/* <ul className="menu w-full">

                                <li>
                                
                <label  className="m-1 text-white font-[600] text-lg tracking-[1px] leading-7">Umrah Packages</label>
                            
                            <ul>
                            {
                                    categories.map(x=>{
                                        return <li className='p-2 text-white'><a href={`/allpackages?category=${x.uid}&type=umrah`}>{x.name}</a></li>
                                    })
                                }
                            </ul>
                                </li>
                            </ul> */}
                        </li>
                               
                               
                            </ul>
                        </li> 
                        

                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
        // <div className='absolute z-10 top-0 left-0 w-full font-poppins'>
            
        //     <div 
        //     className={`
        //         nav 
        //         ${
        //             url!='/'?
        //             'bg-white lg-1:bg-transparent':'bg-transparent'
        //         }
        //     `}
        //     >

        //         {
        //             url!='/'?
        //             <div className='navTopSection h-[40px] items-center px-14 border-b border-b-white hidden lg-1:flex'>
        //                 <div className='flex gap-8 text-xs font-normal text-white'>
        //                     <p>
        //                         valiance@qodeinteractive.com
        //                     </p>
        //                     <p>
        //                         073-082-327-84
        //                     </p>
        //                     <p>
        //                         Maria-Theresien-Platz, 1010 Wien
        //                     </p>
        //                 </div>
        //             </div>:''
        //         }
        //         <div className='logoSection py-9 px-14 flex gap-12'>
                    
        //             {
        //                 url=='/'?
        //                 <div className='logo'>
                            
        //                     <img src={LogoDark} className='w-[200px]'/>
                            
        //                 </div>:
        //                 <div className='logo'>
        //                     <img src={LogoWhite} className='w-[200px] hidden lg-1:block'/>
        //                     <img src={LogoDark} className='w-[200px] block lg-1:hidden'/>
        //                 </div>
        //             }
        //             <div>

        //                 <div  className='hidden lg-1:flex flex-column lg-1:flex-row gap-11'>
        //                     <div className="dropdown dropdown-hover">
        //                         <label tabIndex={0} className="cursor-pointer m-1">Home</label>
        //                         <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black text-white w-52">
        //                             <li >
        //                                 <Link className='font-poppins tracking-[2px]'>
        //                                     MAIN HOME
        //                                 </Link>
        //                             </li>
        //                             <li >
        //                                 <Link className='font-poppins tracking-[2px]'>
        //                                     CONSULTING HOME
        //                                 </Link>
        //                             </li>
        //                         </ul>
        //                     </div>

        //                     <div className="dropdown dropdown-hover">
        //                         <label tabIndex={0} className="cursor-pointer m-1">Pages</label>
        //                         <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-black text-white w-52">
        //                             <li >
        //                                 <Link className='bg-transparent font-poppins tracking-[2px]'>
        //                                     ABOUT US 
        //                                 </Link>
        //                             </li>
        //                             <li >
        //                                 <Link 
        //                                 className='bg-transparent font-poppins tracking-[2px]'
        //                                 to={`${headRoute}/contact`}
        //                                 >
        //                                     CONTACT US
        //                                 </Link>
        //                             </li>
        //                         </ul>
        //                     </div>

        //                 </div>


        //             </div>

        //         </div>
        //     </div>
           
        // </div>
    )
}

const DialogueContactUs=()=>{
    return (
        <dialog id="my_modal_contact" className="modal">
  <div className="modal-box w-11/12 max-w-3xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white">✕</button>
    </form>
    <ContactForm/>
  </div>
</dialog>
    )
}
export default Navbar