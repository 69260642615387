import { Search } from "react-feather"



const MyInput=props=>{

    const {addonLast,className,label,placeholder,onChange,type,addon,inputClass} = props

    return(
        <div className={className}>
            <label>
                {label ?? ''}
            </label>
            <div className="w-full flex items-center">
                <div
                className={`input-addon bg-white lg-1:bg-primary-color px-5 p-[0.82rem] rounded-s-lg ${addon && !addonLast?'block':'hidden'}`}>
                    <Search className="text-black lg-1:text-black"/>
                </div>
                <input
                className={`w-full p-3 font-poppins text-[15px] border-none active:border-none ${addon?'rounded-s-lg rounded-s-none':'rounded'} ${inputClass}`}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                />
                <div
                className={`input-addon bg-white lg-1:bg-primary-color px-5 p-[0.82rem] rounded-s-lg ${addon && addonLast?'block':'hidden'}`}>
                    <Search className="text-black lg-1:text-black"/>
                </div>
            </div>
        </div>
    )
}
export default MyInput