import React, { useEffect, useState } from "react";
import cusImg from "../../assets/images/banner/customize-umrah.png";
import playImg from "../../assets/images/play.svg";
import InputSection from "./InputSection";
const CustomizeSection=({category_id,packages})=> {

  const [showtoast,setShowToast] = useState(false);


    return (
      <>
        {
          showtoast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info">
      <span>Booking Submit</span>
    </div>
          </div>
  
    
  </div>
        }
    <div className="grid grid-cols-1 md:grid-cols-4  bg-primary">
      <div className="relative overflow-x-hidden">
      <img src={cusImg} className="h-full absolute left-[-45%] top-0 " />
      </div>
      
      <div className=" col-span-2 p-10 flex flex-col justify-center items-center">
      <p className="tracking-[3px] text-5xl font-[400]  leading-12 p-2 text-black text-center">Customize Your Umrah
Packages</p>
      <p className="tracking-[2px] text-2xl  leading-8 p-2 text-black text-center">We are specialist in Customized
packages according to your needs.</p>
      <p className="tracking-[1px]  leading-5 p-2 text-black text-center">Allow us to offer Umrah according to your Budget,
Travel Dates, Hotel Choice.</p>
<button className="btn rounded-xl bg-secondary text-white my-5 hover:bg-secondary  hover:border-black" onClick={()=>document.getElementById('my_modal_3').showModal()}><img src={playImg} height={`30px`} width={`30px`}/> Customize</button>
      </div>
      <div className="relative overflow-x-hidden">
      <img src={cusImg} className="h-full absolute right-[-45%] top-0 " />
      </div>
    </div>

    {/* You can open the modal using document.getElementById('ID').showModal() method */}

<dialog id="my_modal_3" className="modal">
  <div className="modal-box w-11/12 max-w-5xl bg-secondary">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 text-white">✕</button>
    </form>
    <InputSection setShowToast={setShowToast}/>
  </div>
</dialog>

      </>
    );
}

export default CustomizeSection