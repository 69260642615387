import React, { useEffect, useState } from "react";



import AOS from "aos";
import "aos/dist/aos.css";
import service from "../../Service/Service";
import Footer from "../footer";

import BannerSection from "../Home3/BannerSection";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "react-feather";
import { Rocket } from "lucide-react";
import parse from 'html-react-parser';
import InputSection from "../Home3/InputSection";
import loadersvg from "../../assets/images/loader.svg";
import Navbar from "../navbar";

const FlightDetail = (props) => {
  const [flights, setFlights] = useState([]);
  const [packagee, setPackage] = useState();
  const [packageData, setPackageData] = useState();
  const [loading, setLoading] = useState(false);
  const [paras, setParas] = useState([]);
  const [name,setName]=useState('');
    const [phone,setPhone]=useState('');
    const [email,setEmail]=useState('');
    const [toast,showToast]=useState(false)
    const [error,setError]=useState('')
    const [refresh,setRefresh]=useState('')
    const [submitted,setSubmitted]=useState(false)
    const [selectedPackage,setSelectedPackage]=useState()
    const navigate=useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();
 
  
    useEffect(()=>{
      
      const token = searchParams.get('package')
      const call = searchParams.get('call')

      // if(call){
      //   setRefresh(call)
      // }
  console.log(token)//123
  if(!token){
    localStorage.clear();
    window.location.href='/'
    return;
  }

  
  setPackage(token)
  
    },[window.location])

    const getAllparas = (id) => {
        service.getAllparas().then((response) => {
          let dep = response.data.filter((x) => x.package_id == id);
          setParas(dep)
         
        });
      };

    useEffect(()=>{
      
    if(packagee){
       let pp={}
        if(packagee==0){

          let params=localStorage.getItem('booking');
          if(params){
            setLoading(true)
            params=JSON.parse(params);

            console.log(params,'params')
            pp=params;
            searchFlights(pp)
          }
        }else{
          setLoading(true)
          service.getpackagebyid({uid:packagee}).then(ree=>{
            console.log(ree.data)
            if(ree.data.length>0){
              pp.arrival=ree.data[0].arr_iatacode;
              pp.departure=ree.data[0].dep_iatacode;
              setSelectedPackage(ree.data[0])
            }
            searchFlights(pp)
          })
        }
        
        // service.postGetFlights({
        //     package:packagee,
        //     ...pp
        // }).then(ress=>{
        //     setLoading(false)
        //     console.log(ress.data.flights);
        //     setFlights(ress.data.flights)
        //     setPackageData(ress.data.package)
        //     getAllparas(ress.data.package.uid)
        // }).catch(err=>{
        //     setLoading(false)
        // })

        console.log(pp,'pppppp')

       
    }
  
    },[packagee])

    const searchFlights=(pp)=>{
      service.getSearchFlights({
        arrival:pp.arrival,
        departure:pp.departure
    }).then(ress=>{

      setTimeout(()=>{setLoading(false)},4000)
        
        console.log(ress.data.flights);
        setFlights(ress.data)
       // setPackageData(ress.data.package)
       // getAllparas(ress.data.package.uid)
       //document.getElementById("flightdetail").scrollIntoView();
       
    }).catch(err=>{
        setLoading(false)
     })
    }

    useEffect(()=>{
      if(!loading ){
        var scrollDiv = document.getElementById("flightdetail").offsetTop;
window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
      }

    },[loading,flights])

    const submit=(e)=>{
      e.preventDefault()
      let booking= localStorage.getItem('bookingresult')
      if(!booking && !selectedPackage){

        setError('Submission failed');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }

      

      booking=JSON.parse(booking);
      if(!name){
        setError('Please Enter Full Name');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!phone){
        setError('Please Enter Phone');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }
      if(!email){
        setError('Please Enter Email');
        showToast(true);
        setTimeout(()=>{showToast(false);},4000)
        return;
      }

      if(selectedPackage){
        submitOnSelectedPkg()
        return;
      }
      
      

        let params={
            
            full_name:name,
            phone,
           email,
           uid:booking.insertId

        }

        service.addBooking(params).then(x=>{
          // document.getElementById('my_modal_4').showModal()
          // if(setShowToast){
          //   setShowToast(true);
          // }

          localStorage.clear()
          setSubmitted(true)
         // navigate(`/`)
        }).catch(err=>{
          console.log(err);
        })
    }
    function toJSONLocal(date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    }
    const submitOnSelectedPkg=()=>{


      let params={
        departure:selectedPackage.dep_iatacode,
        arrival:selectedPackage.arr_iatacode,
        date:toJSONLocal(new Date()),
        full_name:name,
        phone,
       email,
        adult:1,
        children:0,
        infants:0,
        status:'NEW',
        reviews:'',
        description:selectedPackage.uid+` Package`,
        return_date:toJSONLocal(new Date()),
        departure_name:selectedPackage.dep_name,
        arrival_name:selectedPackage.arrival_name
    }

    service.addBooking(params).then(x=>{
      // document.getElementById('my_modal_4').showModal()
      // if(setShowToast){
      //   setShowToast(true);
      // }

      localStorage.clear()
      setSubmitted(true)
    }).catch(err=>{
      console.log(err);
    })
    }
 

  return (
    loading?<div className="absolute z-[4] bg-white w-full h-[100vh] flex justify-center items-center gap-3">
    {/* <Rocket className="text-white text-lg"/> */}
      {/* <p className="text-white text-lg">🚀 Loading...</p> */}
      <img src={loadersvg} className="w-[70%] md:w-[40%]"/>
</div>:
    <div className="relative">
        
<Navbar/>
      <BannerSection/>
      <div className=" flex justify-center items-center" id="flightdetail">
      </div>
      <div className=" flex justify-center items-center" >
        <p className="text-[40px] my-2 font-bold">Flights Detail</p>
      </div>

     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-[90%] md:w-[80%] mx-auto gap-2">

    

    {
         flights ? flights.map(f=>{
return(<div className="grid grid-cols-3 p-4 border border-mLightGray rounded-lg shadow-lg my-2 ">
  {
          toast && <div className="absolute z-[100]">
          <div className="toast toast-top toast-end">
    <div className="alert alert-info bg-[red]">
      <span className="text-white text-lg">{error}</span>
    </div>
          </div>
  
    
  </div>
        }
        
        <div className="col-span-3 flex gap-2 items-center">
        <p className="font-bold text-lg">{f.dep_iatacode+` - ${f.dep_name}`}</p>
        <p className=" text-lg">to</p>
        <p className="font-bold text-lg">{f.arr_iatacode+` - ${f.arr_name}`}</p>
    
    
        </div>
        <div className="col-span-full my-2">
          <hr className="text-gray"/>
        </div>

        <div className="flex flex-col  ">
    <p className="font-bold">Price</p>
        <p className="mt-1">{f.fare} (GBP)</p>
        <p className="font-bold">RETURN</p>
    </div>
 
    <div className="flex flex-col md:justify-center md:items-center">
        <p className="font-bold">AirLine</p>
        <div className="flex gap-2 items-center">
          <img src={service.baseURL+'/uploads/'+f.airline_flag} className="h-[40px] w-[40px]"/>
        <p>{f.airline}</p>
        </div>
    </div>
    <div  className="flex md:justify-end items-center mt-2 md:mt-0">

        <button className="btn rounded-xl bg-primary " onClick={()=>{
            document.getElementById('my_modal_flightdetail').showModal()
        }}>Book now</button>
    </div>
    {/* <div className="col-span-full w-full">
        <hr className="text-mLightGray mt-1"/>
        <div className="flex flex-col md:flex-row w-full">
            {
                f.itineraries[0].segments.map((s,index)=>{
                    return <div className="w-full md:w-[50%]">
                        <p className="text-mLightGray font-bold">Sengment:{index+1}</p>
                        <div className="grid grid-cols-4 gap-2">
                        <p className="text-mLightGray">Arrival: </p>
                        <p>{s.arrival.iataCode} - T:{s.arrival.terminal}</p>
                        <p className="text-mLightGray">Departure: </p>
                        <p>{s.departure.iataCode} - T:{s.departure.terminal}</p>
                        </div>
                    </div>
                })
            }
        </div>
    </div> */}
</div>)
        }):<p className="text-lg text-mLightGray">No Flights</p>
    }

</div>

<div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
  <p className="mt-1 text-md md:text-lg font-bold leading-6 translate-2">How to Book Flights on Alliance Travels?  </p>
  <p className="mt-1 text-md md:text-lg">Step 1: Choose your departure and arrival destination along with your desired dates</p>
  <p className="mt-1 text-md md:text-lg">Step 2: You will see all  multiple airlines options with prices, choose the one you prefer and click on Book Now</p>
  <p className="mt-1 text-md md:text-lg">Step 3: After you Click on Book Now fill in all the required information, Such as</p>
  <p className="mt-1 text-md md:text-lg">Your Name<br/>
Phone Number<br/>
Email Address </p>
<p className="mt-1 text-md md:text-lg">Click Submit, and our Customer Support team will call as soon as possible to finalize the booking and payment procedure.</p>
<p className="mt-1 text-md md:text-lg">Alternatively you can contact us on ( clickable phone number green color ) our customer support team will be happy to assist you throughout your travel</p>
</div>
<div className="my-[70px] w-[90%] md:w-[80%] mx-auto grid grid-cols-1">
        {paras.map((x) => {
          return parse(x.description)
        })}
      </div>
      
      <Footer />

      <dialog id="my_modal_flightdetail" className="modal">
  <div className="modal-box w-11/12 max-w-xl ">
    <form method="dialog">
      {!submitted && <div>
      <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Full Name</label>
        <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setName(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Phone Number</label>
        <input type="Number" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setPhone(e.target.value)}}/>
        </div>
        <div className="p-1 flex flex-col">
        <label className="tracking-[1px] font-[500] leading-6 ">Email</label>
        <input type="text" placeholder="Type here" className="p-1 w-full  h-[40px] rounded-lg border" onChange={(e)=>{setEmail(e.target.value)}}/>
        </div>
        <button className="btn bg-primary w-full" onClick={submit}>Submit</button>
      </div>}
    
      {/* if there is a button in form, it will close the modal */}
      {!submitted &&<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 " onClick={()=>{}}>✕</button>}
    </form>
    {submitted && <h5 className="">Booking has been submitted, one of our agents will call you shortly.
    For quick response, please call: (020-3838-0380)</h5>}
    {submitted && <button className="btn bg-primary w-full mt-2" onClick={()=>{navigate('/')}}>Close</button>}
  </div>
</dialog>
    </div>
  );
};



export default FlightDetail;
