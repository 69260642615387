import React from "react";

const Image=props=>{

    return(
            <div className="relative aspect-square">
                <img className={`abosolute h-full w-full object-cover ${props.classes}`} src={props.src}/>
            </div>                
    )
}
export default Image