import { useEffect } from 'react';
import CookiesModal from './components/cookiesModal';
import Home from './views/home';
import ReactWhatsapp from 'react-whatsapp';


function App() {
  useEffect(()=>{

  },[])
  return (
    <div className='w-full'>
        <Home/>
        
    </div>
  );
}

export default App;
